
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid'
import { Avatar, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RkdDataGrid from "../../app/component/dataGrid/RkdDataGrid";
import { IUser } from "../../app/models/user";
import UserForm from "./UserForm";
import RkdCard from "../../app/component/card/RkdCard";
import UserFormUploadPhoto from "./UserFormUploadPhoto";
import UserFormRoles from "./UserFormRoles";
import ConfirmationPopUp from "../../app/component/popup/confirmationPopUp";


function UserList() {
    const { userStore, modalStore, commonStore, snackbarStore } = useStore();

    const gridColumns: GridColumns<IUser> = [
        { field: 'photo', headerName: 'Photo', width: 150, type: 'actions', getActions: 
            (params) => [                
                <Button onClick={(e) => {                     
                    modalStore.open(<UserFormUploadPhoto username={params.row.username} photo={params.row.photo ? params.row.photo : '/assets/user.png'} />);
                }}>
                    <Avatar src={params.row.photo ? params.row.photo : '/assets/user.png'} alt='Prev user photo' />
                </Button>                
            ]
        },
        { field: 'username', headerName: 'Username', width: 150, type: "string" },
        { field: 'displayName', headerName: 'Display Name', width: 300, type: "string" },
        { field: 'email', headerName: 'Email', width: 200, type: "string" },
        { field: 'actions', headerName: 'Action', width: 200, type: 'actions', getActions: (params) => [
            <GridActionsCellItem icon={<EditIcon />} label="Edit"  onClick={() => {
                modalStore.setLoading(true);
                modalStore.open(<UserForm username={params.row.username} />);
            }} />,
            <GridActionsCellItem icon={<SupervisedUserCircleIcon />} label="Roles"  onClick={() => {
                modalStore.setLoading(true);
                modalStore.open(<UserFormRoles username={params.row.username} />);
            }} />,
            <GridActionsCellItem icon={<VpnKeyIcon />} label="Roles"  onClick={() => {                
                modalStore.open(
                    <ConfirmationPopUp 
                        submitAction={() => userStore.resetPassword(params.row.username)} 
                        afterSubmit={() => snackbarStore.show('success','Success reset password')} 
                        text='Are sure want to reset password user:' 
                        id={params.row.username} type='warning' 
                    />
                );
            }} />,
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete"  onClick={() => {                
                modalStore.open(
                    <ConfirmationPopUp 
                        submitAction={() => userStore.deleteUser(params.row.username)} 
                        afterSubmit={() => {
                            snackbarStore.show('success','Success delete user');
                            userStore.getUserList();
                        }}
                        text='Are sure want to delete user:' 
                        id={params.row.username} type='danger' 
                    />
                    );
            }} />
        ]}
    ]
    useEffect(() => {
        commonStore.setTitlePage('User Management');
    });
    
    useEffect(() => {        
        userStore.getUserList();
    }, [userStore]);

    return (        
        <RkdCard title="Data User" subtitle="">
            <Button
                variant="contained" color="primary"
                onClick={() => modalStore.open(<UserForm />)}
            >
                Add New User
            </Button>
            <RkdDataGrid
                gridStore={userStore}
                columns={gridColumns}
            />
        </RkdCard>                    
    );
}

export default observer(UserList);