import React, { useEffect, useState } from "react";
import { GridColDef, GridColumnGroupingModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { IDailyInjections, IDailyInjectionTable } from "../../../app/models/dailysheet";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

interface IDataProps {
  data: IDailyInjections;
}

function DailysheetInjectionWellsTable(props: IDataProps) {
  const { data } = props;
  const { dailysheetStore } = useStore();

  const [injection, setInjection] = useState(data.table);

  const handleRowEdit = (row: any) => {
    const temp: IDailyInjectionTable[] = injection.map((current) => {
      if (current.wellId !== row.wellId) return current;
      return {
        wellId: row.wellId,
        wellName: row.wellName,
        fcv: row.fcv,
        fcvPrev: row.fcvPrev,
        whp: row.whp,
        whpPrev: row.whpPrev,
        wht: row.wht,
        whtPrev: row.whtPrev,
        hourDuration: row.hourDuration,
        hourDurationPrev: row.hourDurationPrev,
        brineRate: row.brineRate,
        brineRatePrev: row.brineRatePrev,
        avgBrineRate: row.avgBrineRate,
        avgBrineRatePrev: row.avgBrineRatePrev,
        totalBrineRate: row.totalBrineRate,
        totalBrineRatePrev: row.totalBrineRatePrev,
      };
    });
    setInjection(temp);

    return row;
  };

  const columns: GridColDef<GridValidRowModel>[] = [
    { field: "wellName", headerName: "", width: 110, headerAlign: "center" },
    {
      field: "fcvPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "fcv",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "whpPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "whp",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "whtPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "wht",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "hourDurationPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "hourDuration",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "brineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "brineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "avgBrineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "avgBrineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "totalBrineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "totalBrineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      flex: 1,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "Well Name",
      description: "",
      headerAlign: "center",
      children: [{ field: "wellName" }],
    },
    {
      groupId: "FCV (%)",
      headerAlign: "center",
      children: [{ field: "fcvPrev" }, { field: "fcv" }],
    },
    {
      groupId: "WHP (Bar)",
      headerAlign: "center",
      children: [{ field: "whpPrev" }, { field: "whp" }],
    },
    {
      groupId: "WHT (Deg C)",
      headerAlign: "center",
      children: [{ field: "whtPrev" }, { field: "wht" }],
    },
    {
      groupId: "Duration (hr)",
      headerAlign: "center",
      children: [{ field: "hourDurationPrev" }, { field: "hourDuration" }],
    },
    {
      groupId: "Brine Rate (M3/hr)",
      headerAlign: "center",
      children: [{ field: "brineRatePrev" }, { field: "brineRate" }],
    },
    {
      groupId: "Average Brine Rate (M3/hr)",
      headerAlign: "center",
      children: [{ field: "avgBrineRatePrev" }, { field: "avgBrineRate" }],
    },
    {
      groupId: "Total Brine Rate (M3/hr)",
      headerAlign: "center",
      children: [{ field: "totalBrineRatePrev" }, { field: "totalBrineRate" }],
    },
  ];

  useEffect(() => {
    dailysheetStore.setInjections(data.locationId, injection);
  }, [dailysheetStore, data.locationId, injection]);

  return (
    <StyledDataGridPremium
      rows={data.table}
      getRowId={(row) => row.wellId}
      columns={columns}
      showCellVerticalBorder
      showColumnVerticalBorder
      columnGroupingModel={columnGroupingModel}
      autoHeight
      onProcessRowUpdateError={(e) => console.error(e)}
      processRowUpdate={handleRowEdit}
      hideFooter
    />
  );
}

export default observer(DailysheetInjectionWellsTable);
