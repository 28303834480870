import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';

import { Typography } from '@mui/material';
import MenuNav from './MenuNav';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';

interface Props {
  window?: () => Window;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const Sidebar = (props: Props) => {
  const { accountStore } = useStore();
  const { window, mobileOpen, handleDrawerToggle, drawerWidth } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, }}
      aria-label="menu"
    >
      {/* Mobile */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'flex', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          flexDirection: 'column',
        }}
      >
        <Box component='div' sx={{ bgcolor:'background.default' , height: '100%', px: 2.5, py: 3 }}>
          <Box sx={{ display: 'inline-flex', bgColor: 'primary' }}>
            <img src="/assets/images/smgp-logo.svg" alt="Logo" width={200} />
          </Box>
          <Box sx={{ mt: 4, px: 1, py: 2, display: 'flex', alignItems: 'center', bgcolor: 'info.light', borderRadius: 2, }} >
            <Avatar alt={accountStore.account?.username} src={accountStore.account?.photo} />
            <Typography sx={{ pl: 2 }}>{`Welcome, ${accountStore.account?.displayName ?? 'guest'}`}</Typography>
          </Box>
          <MenuNav />
        </Box>
      </Drawer>

      {/* PC */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'flex' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 'dotted', borderColor: 'info.light' },
          flexDirection: 'column',
        }}
        open
      >
        <Box component='div' sx={{ bgcolor:'background.default', height: '100%', px: 2.5, py: 3 }}>
          <Box sx={{ display: 'inline-flex', }}>
            <img src="/assets/images/smgp-logo.svg" alt="Logo" width={200} />
          </Box>
          <Box sx={{ mt: 4, mb: 5, px: 1, py: 2, display: 'flex', alignItems: 'center', bgcolor: 'info.light', borderRadius: 2 }} >
            <Avatar alt={accountStore.account?.username} src={accountStore.account?.photo} />
            <Typography sx={{ pl: 2 }} variant='h3' color='info.main'>{`Welcome, ${accountStore.account?.displayName ?? 'guest'}`}</Typography>
          </Box>
          <MenuNav />
        </Box>
      </Drawer>
    </Box>
  );
};

export default observer(Sidebar);
