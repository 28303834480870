import { useEffect, useState } from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MuiList from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { Menu } from '../../config/menu';
import theme from '../../config/theme';
import { useStore } from '../../stores/store';

const List = styled(MuiList)({
  '&& .MuiTypography-root': {
    color: theme.palette.info.main,
  },
  '&& .MuiListItemIcon-root ': {
    color: theme.palette.info.main,
  },
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: theme.palette.primary.light,
    '&, & .MuiListItemIcon-root,  .MuiTypography-root ': {
      color: theme.palette.primary.main,
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.info.light,
  },
});

interface Props {
  menu: string;
  submenu: Array<Menu>;
  icon?: JSX.Element;
}

const DropdownMenu = (props: Props) => {
  const { submenu, icon, menu } = props;
  const { accountStore } = useStore();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isSelected = (route: string) => {
    const parentPath = location.pathname.split('/');
    const routePath = route.split('/');
    return parentPath[1] === routePath[1];
  };
  
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(false);
    const isSelected = (route: string) => {
      const parentPath = location.pathname.split('/');
      const routePath = route.split('/');
      return parentPath[1] === routePath[1];
    };
    submenu.forEach(item => {
      if(item.route && isSelected(item.route)){
        setOpen(true);
      }         
    });    
  }, [submenu, location]);
  
  return (
    <>
      <ListItemButton onClick={handleClick} sx={{ ':hover': 'primary.main' }}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={menu} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {submenu.map((item, idx) => {
            if (item.route && (item.roles === undefined || (item.roles && accountStore.isInRole(item.roles)))) {
              return (
                <ListItemButton
                  selected={isSelected(item.route)}
                  key={idx}
                  sx={{ pl: 10 }}
                  component={Link}
                  to={item.route}
                >
                  <ListItemText primary={item.title} />
                </ListItemButton>
              );
            }
            return false;
          })}
        </List>
      </Collapse>
    </>
  );
};

export default DropdownMenu;
