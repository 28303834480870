export enum FileType {
  Image = "image/*",
  Audio =  "audio/*",
  Vieo = "video/*" ,
  Document = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint"
} ;

export enum sex {
  Male = 'Laki - Laki',
  Female = 'Perempuan'
}

export enum Roles {
  Superadmin = "Superadmin",
  Admin = "Admin",
}

// export const displayText = (role: Roles) => {
//   switch (role)
//   {
//     case Roles.Superadmin: return "Super Administrator";
//     case Roles.Admin: return "Administrator";
//     default: return "";
//   }
// }

//Notes: yang dikirim di db keys nya

export function enumToArray(obj: any) {
  return Object.keys(obj).map((value) => ({ value , text: obj[value] }));
}  

export const enumMap = <T>(obj: {}, callbackFn: (key: string, value: string, index: number) => T): T[] => {
    // const enumValues: string[] = Object.values(obj);
    return Object.keys(obj).map<T>(
        (k, i) => callbackFn(k, k, i)
    )
}

export function formatDateToMMDDYYYY(date: Date) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${month}-${day}-${year}`;
};