import * as React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { useStore } from '../../stores/store';
import Sidebar from './Sidebar';
import AppBarMenu from './AppBarMenu';

const drawerWidth = 280;


function DashboardLayout() {    
  const { accountStore } = useStore();
  const [mobileOpen, setMobileOpen] = React.useState(false);   

  if (!accountStore.isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }   

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <>      
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* AppBarMenu */}
        <AppBarMenu handleDrawerToggle={handleDrawerToggle} drawerWidth={drawerWidth} />

        {/* Sidebar */}
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
        />

        {/* Main */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 5,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            paddingTop: 15
          }}
          height="100vh"
        >            
          <Outlet />
        </Box>
      </Box>      
    </>
  );
}

export default observer(DashboardLayout);
