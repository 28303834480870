import { makeAutoObservable, runInAction } from "mobx";

export interface IDialog {
    title?: string,
    description?: string,
    closeText?: string,
    action?: () => Promise<any>,
    actionText?: string
}

export default class DialogStore {
    dialog: IDialog = {
        title: undefined,
        description: undefined,
        closeText: undefined,
        action: undefined,
        actionText: undefined
    }

    isOpen = false;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    open = (dialog: IDialog) => {
        this.dialog.title = dialog.title;
        this.dialog.description = dialog.description;
        this.dialog.closeText = dialog.closeText;
        this.dialog.action = dialog.action;
        this.dialog.actionText = dialog.actionText;
        this.isOpen = true;
    }

    close = () => {
        this.isOpen = false;
        this.dialog.title = undefined;
        this.dialog.description = undefined;
        this.dialog.closeText = undefined;
        this.dialog.action = undefined;
        this.dialog.actionText = undefined;
    }

    executeActions = async () => {
        if (this.dialog.action) {
            this.isLoading = true;
            try {
                await this.dialog.action();
                runInAction(() => this.close());
            } catch (error) {
                throw error;
            } finally {
                runInAction(() => this.isLoading = false);
            }
            
        }
    }
}