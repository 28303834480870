import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import AppBar from '@mui/material/AppBar';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import AvatarAppBar from './AvatarAppBar';

interface Props {
  handleDrawerToggle: () => void;
  drawerWidth: number;
}

const AppBarMenu = (props: Props) => {
  const { handleDrawerToggle, drawerWidth } = props;  
  const { commonStore } = useStore();
  const { titlePage } = commonStore;

  return (
    <>
      {/* Navbar */}
      <AppBar
        position="fixed"
        
        elevation={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          p: 2,
          backdropFilter: "blur(10px)",  
          bgcolor:'background.default'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="div"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography variant="h1" noWrap component="h1" color='info.main'>
              {titlePage}
            </Typography>
            <Breadcrumbs />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="info"
            >
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="info"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <AvatarAppBar />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default observer(AppBarMenu);
