import React from 'react';
import BreadcrumbsComp from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useLocation, Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';

function capitalizeFirstLetter(str: string) {
  return str.replace(/\b\w+/g, function (s: string) {    
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  })
}

const Breadcrumbs = () => {
  let location = useLocation();
  const pathnames = location.pathname.split("/").filter(x => x);
  return (
    <BreadcrumbsComp aria-label="breadcrumb" sx={{ pt: 1 }}>
      <Link
        to="/"
        color="info"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <HomeIcon sx={{ fontSize: 16, color: 'primary.main' }} />
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="info.main" key={index}>
            {capitalizeFirstLetter(value)}
          </Typography>
        ) : (
          <Typography component={Link} to={to} color="info.main" variant='body1' key={index} sx={{ textDecoration: 'none', color: 'primary.main' }}>            
              {capitalizeFirstLetter(value)}            
          </Typography>
        );
      })}
    </BreadcrumbsComp>
  );
};

export default Breadcrumbs;
