import React, { useEffect, useState } from "react";
import { GridColDef, GridColumnGroupingModel, GridValidRowModel } from "@mui/x-data-grid-premium";
import { IDailyMonitoringTable } from "../../../app/models/dailysheet";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

interface IDataProps {
  data: IDailyMonitoringTable[];
}

function DailysheetMonitoringWellsTable(props: IDataProps) {
  const { data } = props;
  const { dailysheetStore } = useStore();

  const [monitoring, setMonitoring] = useState(data);

  const handleRowEdit = (row: any) => {
    const temp: IDailyMonitoringTable[] = monitoring.map((current) => {
      if (current.wellId !== row.wellId) return current;
      return {
        wellId: row.wellId,
        wellName: row.wellName,
        whp: row.whp,
        whpPrev: row.whpPrev,
        wht: row.wht,
        whtPrev: row.whtPrev,
      };
    });
    setMonitoring(temp);

    return row;
  };

  const columns: GridColDef<GridValidRowModel>[] = [
    { field: "wellName", headerName: "", width: 110, headerAlign: "center" },
    {
      field: "whpPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "whp",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "whtPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "wht",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      align: "center",
      editable: true,
      flex: 1,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: "Well Name",
      headerAlign: "center",
      children: [{ field: "wellName" }],
    },
    {
      groupId: "WHP (Bar)",
      headerAlign: "center",
      children: [{ field: "whpPrev" }, { field: "whp" }],
    },
    {
      groupId: "WHT (Deg C)",
      headerAlign: "center",
      children: [{ field: "whtPrev" }, { field: "wht" }],
    },
  ];

  useEffect(() => {
    dailysheetStore.setMonitorings(monitoring);
  }, [dailysheetStore, monitoring]);

  return (
    <>
      <StyledDataGridPremium
        rows={data}
        getRowId={(row) => row.wellId}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEdit}
        hideFooter
      />
    </>
  );
}

export default observer(DailysheetMonitoringWellsTable);
