import { Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from './app/stores/store';

import { ThemeProvider } from '@mui/material/styles';
import theme from './app/config/theme';

import DashboardLayout from './app/component/layout/DashboardLayout';
import Home from './features/home/Home';
import ExamplePage from './features/example/ExamplePage';
import ExampleForm from './features/example/ExampleForm';
import UserPage from './features/user/UserPage';
import Tester from './features/tester/Tester';
import ServerError from './features/errors/ServerError';
import NotFound from './features/errors/NotFound';
import LoginPage from './features/account/LoginPage';
import RkdModal from './app/component/helper/RkdModal';
import RkdSnackbar from './app/component/helper/RkdSnackbar';
import RkdDialog from './app/component/helper/RkdDialog';
import RkdBackdrop from './app/component/helper/RkdBackdrop';
import { LicenseInfo } from "@mui/x-license-pro";
import RouteAuth from './app/component/helper/RouteAuth';
import AccessDenied from './features/errors/AccessDenied';
import { Roles } from './app/config/enum';
import DailysheetPageIndex from './features/dailysheet/DailysheetPageIndex';
import QueryPageIndex from './features/query/QueryPageIndex';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY || "");

const App = () => {
  const location = useLocation();
  const { commonStore, accountStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      accountStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore]);

  if (!commonStore.appLoaded) return <RkdBackdrop />

  return (
    <ThemeProvider theme={theme}>
      <RkdModal />
      <RkdDialog />
      <RkdSnackbar />
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route element={<DashboardLayout />} >
          <Route index={true} element={<Home></Home>} />

          {/* Dailysheet */}
          <Route path='/dailysheet' element={<DailysheetPageIndex />} />

          {/* Query Reporting */}
          <Route path='/query-reporting' element={<QueryPageIndex />} />

          <Route path='/example' element={<ExamplePage />} />
          <Route path='/example/edit/:id' element={<ExampleForm key={location.key} />} />
          <Route path='/example/create' element={<ExampleForm key={location.key} />} />
          <Route element={<RouteAuth roles={[Roles.Superadmin]} />}>
            <Route path='/users/*' element={<UserPage />} />
            <Route path='/tester' element={<Tester />} />
          </Route>
          {/* Error */}
          <Route path='/access-denied' element={<AccessDenied />} />
          <Route path='/server-error' element={<ServerError />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default observer(App);
