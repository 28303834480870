// import PeopleIcon from '@mui/icons-material/People';
// import CodeIcon from '@mui/icons-material/Code';
import { Home, Checklist, QueryStats } from "@mui/icons-material";
import { Roles } from "./enum";

export interface Menu {
  title: string;
  icon?: JSX.Element;
  route?: string;
  roles?: Roles[];
  submenu?: Array<Menu>;
}

export const menu: Array<Menu> = [
  {
    title: "Home",
    icon: <Home />,
    route: "/",
  },
  {
    title: "Daily Sheet",
    icon: <Checklist />,
    route: "/dailysheet",
  },
  {
    title: "Query",
    icon: <QueryStats />,
    route: "/query-reporting",
  },
  // {
  //   title: 'User Management',
  //   icon: <PeopleIcon />,
  //   route: '/users',
  //   roles: [Roles.Superadmin]
  // },
  // {
  //   title: 'Development Tools',
  //   icon: <CodeIcon />,
  //   roles: [Roles.Superadmin],
  //   submenu: [
  //     { title: 'Tester', route: '/tester' },
  //     { title: 'Server Error', route: '/server-error' },
  //     { title: 'Not Found', route: '/not-found' },
  //   ],
  // },
];
