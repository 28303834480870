import { LoadingButton } from "@mui/lab";
import { Button, CircularProgress, Collapse, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import RkdCard from "../../app/component/card/RkdCard";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import DynamicAccordion from "./component/DynamicAccordion";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function DailysheetPageIndex() {
  const { dailysheetStore, accountStore, snackbarStore } = useStore();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [isOpenList, setIsOpenList] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedDate) {
      dailysheetStore.getDailyByDate(selectedDate);
    }
  }, [dailysheetStore, selectedDate]);

  useEffect(() => {
    accountStore.getUser().then(() => {
      dailysheetStore.setCreatedBy(accountStore.account!.username, accountStore.account!.username);
    });
  }, [accountStore, dailysheetStore]);

  const dailySheetList = [
    {
      title: "Productions",
      component: (
        <Box>
          {dailysheetStore.dailysheet?.productions.map((production, index) => (
            <DynamicAccordion identifier="Production" dataProduction={production} index={index} />
          ))}
        </Box>
      ),
    },
    {
      title: "Injections",
      component: (
        <Box>
          {dailysheetStore.dailysheet?.injections.map((injection, index) => (
            <DynamicAccordion identifier="Injection" dataInjection={injection} index={index} />
          ))}
        </Box>
      ),
    },
    {
      title: "Monitorings",
      component: (
        <Box>
          {dailysheetStore.dailysheet?.monitorings.map((monitoring, index) => (
            <DynamicAccordion identifier="Monitoring" dataMonitoring={monitoring} index={index} />
          ))}
        </Box>
      ),
    },
  ];

  return (
    <RkdCard title="Daily Sheet" subtitle="Add Edit & Delete Daily Sheet from this Page">
      <Grid container>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(e) => setSelectedDate(e)}
              value={selectedDate}
              inputFormat="dd MMM yyyy"
              label="Date"
              renderInput={(props) => <TextField label="Date" {...props} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" component="h2" sx={{ alignItems: "center" }}>
            STATUS - <b>{dailysheetStore.dailysheet?.status}</b>
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Box>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={false}
              onClick={() => console.log("copy previous")}
              sx={{ padding: "15px 20px" }}
            >
              Copy Previous
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {dailysheetStore.loadingGetData ? (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {dailySheetList.map((item) => (
              <Box
                sx={{
                  p: "16px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: "1px solid #DEDEDE",
                  background: "#FFF",
                  mb: "12px",
                }}
              >
                <Box
                  onClick={() => {
                    let temp = [...isOpenList];
                    if (temp.includes(item.title)) {
                      temp = isOpenList.filter((isOpenItem) => isOpenItem !== item.title);
                    } else {
                      temp.push(item.title);
                    }
                    setIsOpenList(temp);
                  }}
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Box>
                    <Box sx={{ fontWeight: "bold", color: "#212121" }}>{item.title}</Box>
                    <Box sx={{ fontSize: "12px" }}>See Details</Box>
                  </Box>
                  <Box>{isOpenList.includes(item.title) ? <ExpandLess /> : <ExpandMore />}</Box>
                </Box>
                <Collapse sx={{ mt: "24px" }} in={isOpenList.includes(item.title)} timeout="auto" unmountOnExit>
                  {item.component}
                </Collapse>
              </Box>
            ))}
            <TextField
              id="outlined-multiline-flexible"
              label="Remarks"
              onChange={(e) => dailysheetStore.setRemarks(e.target.value)}
              fullWidth
              multiline
              rows={4}
            />
          </>
        )}
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
        <Button
          variant="contained"
          disabled={dailysheetStore.loadingForm}
          color="primary"
          sx={{ backgroundColor: "#fab933", "&:hover": { backgroundColor: "#dea123" } }}
          onClick={() => dailysheetStore.draftDailySheet().then(() => snackbarStore.show("success", "Saved as Draft"))}
        >
          Save as Draft
        </Button>
        <Button
          variant="contained"
          disabled={dailysheetStore.loadingForm}
          color="success"
          sx={{ mr: 1 }}
          onClick={() =>
            dailysheetStore.createDailySheet().then(() => {
              snackbarStore.show("success", "Saved");
              navigate("/dailysheet");
            })
          }
        >
          Save
        </Button>
      </Grid>
    </RkdCard>
  );
}

export default observer(DailysheetPageIndex);
