import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Collapse, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDailyChart, IDailyInjections, IDailyMonitorings, IDailyProductions } from "../../../app/models/dailysheet";
import DailysheetWellsParameterTable from "./DailysheetWellsParameterTable";
import DailysheetCharts from "./DailysheetCharts";
import React, { ReactNode, useMemo, useState } from "react";
import DailysheetMonitoringWellsTable from "./DailysheetMonitoringWellsTable";
import DailysheetInjectionWellsTable from "./DailysheetInjectionWellsTable";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import DynamicBarShowChartDialog from "./DynamicBarShowChartDialog";

type TDynamicAccordionProps = {
  dataProduction?: IDailyProductions;
  dataInjection?: IDailyInjections;
  dataMonitoring?: IDailyMonitorings;
  identifier: string;
  index: number;
};

export default function DynamicAccordion({ dataMonitoring, dataProduction, dataInjection, identifier, index }: TDynamicAccordionProps) {
  const [openChartDialog, setOpenChartDialog] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const title = useMemo(() => identifier, [identifier]);

  const dynamicData = useMemo(() => {
    let name: string | undefined = "";
    let chart: IDailyChart[] | undefined = [];
    let gridData: ReactNode = <></>;
    if (identifier === "Production" && dataProduction) {
      name = dataProduction?.lpSeparatorName;
      chart = dataProduction?.charts;
      gridData = <DailysheetWellsParameterTable data={dataProduction} />;
    }
    if (identifier === "Injection" && dataInjection) {
      name = dataInjection?.locationName;
      chart = dataInjection?.charts;
      gridData = <DailysheetInjectionWellsTable data={dataInjection} />;
    }
    if (identifier === "Monitoring") {
      name = "";
      chart = dataMonitoring?.charts;
      gridData = <DailysheetMonitoringWellsTable data={dataMonitoring?.table ?? []} />;
    }

    return { name, chart, gridData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier]);

  return (
    <Box
      sx={{
        p: "16px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "1px solid #DEDEDE",
        background: "#FFF",
        mb: "12px",
      }}
    >
      <Box onClick={() => setIsOpen(!isOpen)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Box sx={{ fontWeight: "bold", color: "#212121" }}>{`${title} ${index + 1}`}</Box>
          <Box sx={{ fontSize: "12px" }}>{dynamicData.name}</Box>
        </Box>
        <Box>{isOpen ? <ExpandLess /> : <ExpandMore />}</Box>
      </Box>
      <Collapse sx={{ mt: "24px" }} in={isOpen} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "12px" }}>
          <Button variant="contained" color="primary" type="submit" onClick={() => setOpenChartDialog(true)}>
            Open Chart
          </Button>
        </Box>
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <Box sx={{ width: 2100, overflowX: "auto", height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-beetwen" }}>
            <Box sx={{ width: "100%" }}>{dynamicData.gridData}</Box>
          </Box>
        </Box>
      </Collapse>
      {openChartDialog ? (
        <DynamicBarShowChartDialog series={dynamicData.chart} openChartDialog={openChartDialog} setOpenChartDialog={setOpenChartDialog} />
      ) : undefined}
    </Box>
  );
}
