import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RkdCard from "../../app/component/card/RkdCard";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  TextField,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  SelectChangeEvent,
  Divider,
} from "@mui/material";
import QueryCharts from "./component/QueryCharts";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { IQueryFieldSelectors, IQueryObjectSelectors, IQueryRequest } from "../../app/models/query";
import { width } from "@mui/system";

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function QueryPageIndex() {
  const { queryStore } = useStore();

  const [objectSelectors, setObjectSelectors] = useState<IQueryObjectSelectors[]>(queryStore.selectors.objectSelectors);
  const [fieldSelectors, setFieldSelectors] = useState<IQueryFieldSelectors[]>(queryStore.selectors.fieldSelectors);
  const [selectedMultipleObject, setSelectedMultipleObject] = useState<string[]>([]);
  const [displaySelectedObject, setDisplaySelectedObject] = useState<string[]>([]);
  const [selectedMultipleField, setSelectedMultipleField] = useState<string[]>([]);
  const [displaySelectedField, setDisplaySelectedField] = useState<string[]>([]);
  const [allObjectSelected, setAllObjectSelected] = useState(false);
  const [allFieldSelected, setAllFieldSelected] = useState(false);
  const [someObjectSelected, setSomeObjectSelected] = useState(false);
  const [someFieldSelected, setSomeFieldSelected] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleCheckAllObject = () => {
    let temp = objectSelectors;
    if (allObjectSelected) {
      temp.forEach((item) => {
        item.selected = false;
      });
      setAllObjectSelected(false);
    } else {
      temp.forEach((item) => {
        item.selected = true;
      });
      setAllObjectSelected(true);
    }
    setSomeObjectSelected(false);
    setObjectSelectors(temp);
  };

  const handleCheckAllField = () => {
    let temp = fieldSelectors;
    if (allFieldSelected) {
      temp.forEach((item) => {
        item.selected = false;
      });
      setAllFieldSelected(false);
    } else {
      temp.forEach((item) => {
        item.selected = true;
      });
      setAllFieldSelected(true);
    }
    setSomeFieldSelected(false);
    setFieldSelectors(temp);
  };

  const handleLoadData = () => {
    let objectSelectorTemp: IQueryObjectSelectors[] = [];
    let fieldSelectorTemp: IQueryFieldSelectors[] = [];
    objectSelectors.forEach((val) => selectedMultipleObject.includes(val.value) && objectSelectorTemp.push({ ...val, selected: true }));
    fieldSelectors.forEach((val) => selectedMultipleField.includes(val.value) && fieldSelectorTemp.push({ ...val, selected: true }));

    if (startDate && endDate) {
      const data: IQueryRequest = {
        startDate: startDate,
        endDate: endDate,
        objectSelectors: objectSelectorTemp,
        fieldSelectors: fieldSelectorTemp,
      };
      queryStore.getQueryChart(data);
    }
  };

  const handleSelectObject = async (event: SelectChangeEvent<typeof selectedMultipleObject>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    if (identifier === "objectSelector") {
      objectSelectors.forEach((val) => value.includes(val.value) && result.push(val.name));
      setDisplaySelectedObject(result);
      setSelectedMultipleObject(typeof value === "string" ? value.split(",") : value);
    } else {
      fieldSelectors.forEach((val) => value.includes(val.value) && result.push(val.name));
      setDisplaySelectedField(result);
      setSelectedMultipleField(typeof value === "string" ? value.split(",") : value);
    }
  };

  useEffect(() => {
    queryStore.getSelector().then((data) => {
      if (data) {
        setObjectSelectors(data?.objectSelectors);
        setFieldSelectors(data?.fieldSelectors);
      }
    });
  }, [queryStore]);

  useEffect(() => {
    const allObject = objectSelectors.every((item) => item.selected);
    const allField = fieldSelectors.every((item) => item.selected);

    if (allObject) {
      setAllObjectSelected(allObject);
    } else {
      const someObject = objectSelectors.some((item) => item.selected);
      setSomeObjectSelected(someObject);
      setAllObjectSelected(allObject);
    }

    if (allField) {
      setAllFieldSelected(allField);
    } else {
      const someField = fieldSelectors.some((item) => item.selected);
      setSomeFieldSelected(someField);
      setAllFieldSelected(allField);
    }
  }, [allObjectSelected, objectSelectors, fieldSelectors, allFieldSelected]);

  return (
    <RkdCard title="Query Reporting" subtitle="Add Edit & Delete Query Reporting from this Page">
      {queryStore.loadingGetSelector ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e) => setStartDate(e)}
                value={startDate}
                inputFormat="dd MMM yyyy"
                label="Start Date"
                renderInput={(props) => <TextField label="Start Date" {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                onChange={(e) => setEndDate(e)}
                value={endDate}
                inputFormat="dd MMM yyyy"
                label="End Date"
                renderInput={(props) => <TextField label="End Date" {...props} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Object Selector</InputLabel>
              <Select
                multiple
                value={selectedMultipleObject}
                onChange={(e) => handleSelectObject(e, "objectSelector")}
                input={<OutlinedInput label="Object Selector" />}
                renderValue={() => displaySelectedObject.join(", ")}
                MenuProps={MenuProps}
              >
                {objectSelectors.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedMultipleObject.indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-multiple-checkbox-label">Field Selector</InputLabel>
              <Select
                multiple
                onChange={(e) => handleSelectObject(e, "fieldSelector")}
                value={selectedMultipleField}
                input={<OutlinedInput label="Field Selector" />}
                renderValue={() => displaySelectedField.join(", ")}
                MenuProps={MenuProps}
              >
                {fieldSelectors.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedMultipleField.indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 1 }}>
            <Box sx={{ height: "100%" }}>
              <Button fullWidth sx={{ height: "100%" }} variant="contained" color="success" onClick={() => handleLoadData()}>
                Load Data
              </Button>
            </Box>
            <Box sx={{ height: "100%" }}>
              <Button fullWidth sx={{ height: "100%" }} variant="contained" color="success">
                Download Data
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ mb: "12px" }}>
            <Divider sx={{ my: "24px" }} />
            <Box sx={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "16px" }}>Charts</Box>
          </Grid>
          <Grid item xs={12}>
            <QueryCharts series={queryStore.queryChart} />
          </Grid>
          {/* <Grid item xs={12} sx={{ width: "40%", mr: 4 }}>
            <FormControlLabel
              label="Object Selector"
              control={<Checkbox checked={allObjectSelected} indeterminate={someObjectSelected} onChange={handleCheckAllObject} />}
            />
            <Box sx={{ ml: 2 }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} direction="row">
                {objectSelectors.map((item, idx) => (
                  <Grid item xs={4} key={idx}>
                    <FormControlLabel label={item.name} control={<Checkbox checked={item.selected} onChange={() => handleSelectedObject(idx)} />} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid> */}

          {/* <Box sx={{ width: "20%" }}>
            <FormControlLabel
              label="Field Selector"
              control={<Checkbox checked={allFieldSelected} indeterminate={someFieldSelected} onChange={handleCheckAllField} />}
            />
            <Box sx={{ ml: 2 }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} direction="column">
                {fieldSelectors.map((item, idx) => (
                  <Grid item xs={8} key={idx}>
                    <FormControlLabel label={item.name} control={<Checkbox checked={item.selected} onChange={() => handleSelectedField(idx)} />} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box> */}
        </Grid>
      )}
    </RkdCard>
  );
}

export default observer(QueryPageIndex);
