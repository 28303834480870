import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IQueryChart, IQueryGetSelector, IQueryRequest } from "../models/query";

export default class QueryStore {
  selectors: IQueryGetSelector = {
    objectSelectors: [],
    fieldSelectors: [],
  };

  loadingGetSelector = false;

  queryChart: IQueryChart[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getSelector = async () => {
    this.loadingGetSelector = true;
    try {
      const data = await agent.Query.list();
      this.setSelector(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingGetSelector = false));
    }
  };

  setSelector = (data: IQueryGetSelector) => {
    this.selectors = data;
  };

  getQueryChart = async (data: IQueryRequest) => {
    try {
      const chart = await agent.Query.load(data);
      this.setQueryChart(chart.charts);
    } catch (error) {
      console.log(error);
    }
  };

  setQueryChart = (data: IQueryChart[]) => {
    this.queryChart = data;
  };
}
