import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Roles } from '../../config/enum';
import { useStore } from '../../stores/store';

interface Props{
  roles: Array<Roles>; 
}

const RouteAuth = (props: Props) => {
  const { roles } = props;
  const { accountStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if(!accountStore.isLoggedIn || !accountStore.isInRole(roles)) {    
      navigate('/access-denied')
    }
  });

  return (
    <Outlet />
  );
  
};

export default RouteAuth;