import React, { useEffect, useState } from "react";
import { GridValidRowModel, GridColDef, GridColumnGroup, GridRowHeightParams } from "@mui/x-data-grid-premium";
import { IDailyProdHpSeparator, IDailyProductions } from "../../../app/models/dailysheet";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import { Box } from "@mui/system";
import { StyledDataGridPremium } from "../../../app/component/StyledDataGridPremium";

interface IDataProps {
  data: IDailyProductions;
}

function DailysheetWellsParameterTable(props: IDataProps) {
  const { data } = props;
  const { dailysheetStore } = useStore();
  const [production, setProduction] = useState([
    {
      lpSeparatorId: "",
      lpSeparatorName: "",
      lpInletPress: 0,
      lpInletPressPrev: 0,
      lpInletTemp: 0,
      lpInletTempPrev: 0,
      lpSteamRate: 0,
      lpSteamRatePrev: 0,
      lpBrineRate: 0,
      lpBrineRatePrev: 0,
    },
  ]);
  const [wells, setWells] = useState([
    {
      wellId: "",
      wellName: "",
      fcv: 0,
      fcvPrev: 0,
      whp: 0,
      whpPrev: 0,
      wht: 0,
      whtPrev: 0,
    },
  ]);
  const [hpSeparators, setHpSeparators] = useState([
    {
      hpSeparatorId: "",
      hpSeparatorName: "",
      hpInletPress: 0,
      hpInletPressPrev: 0,
      hpInletTemp: 0,
      hpInletTempPrev: 0,
      hpSteamRate: 0,
      hpSteamRatePrev: 0,
      hpBrineRate: 0,
      hpBrineRatePrev: 0,
      brinePumpId: "",
      brinePumpName: "",
      bpBrineRate: 0,
      bpBrineRatePrev: 0,
      wells: [
        {
          wellId: "",
          wellName: "",
          fcv: 0,
          fcvPrev: 0,
          whp: 0,
          whpPrev: 0,
          wht: 0,
          whtPrev: 0,
        },
      ],
    },
  ]);

  const handleRowEditHpSep = (row: any) => {
    const temp: IDailyProdHpSeparator[] = hpSeparators.map((current) => {
      if (current.hpSeparatorId !== row.hpSeparatorId) return current;
      return {
        hpSeparatorId: row.hpSeparatorId,
        hpSeparatorName: row.hpSeparatorName,
        hpInletPress: row.hpInletPress,
        hpInletPressPrev: row.hpInletPressPrev,
        hpInletTemp: row.hpInletTemp,
        hpInletTempPrev: row.hpInletTempPrev,
        hpSteamRate: row.hpSteamRate,
        hpSteamRatePrev: row.hpSteamRatePrev,
        hpBrineRate: row.hpBrineRate,
        hpBrineRatePrev: row.hpBrineRatePrev,
        brinePumpId: row.brinePumpId,
        brinePumpName: row.brinePumpName,
        bpBrineRate: row.bpBrineRate,
        bpBrineRatePrev: row.bpBrineRatePrev,
        wells: row.wells,
      };
    });
    setHpSeparators(temp);

    return row;
  };

  const handleRowEditWell = (row: any) => {
    const temp: any[] = wells.map((current) => {
      if (current.wellId !== row.wellId) return current;
      return {
        wellId: row.wellId,
        wellName: row.wellName,
        fcv: row.fcv,
        fcvPrev: row.fcvPrev,
        whp: row.whp,
        whpPrev: row.whpPrev,
        wht: row.wht,
        whtPrev: row.whtPrev,
      };
    });
    setWells(temp);
    return row;
  };

  const handleRowEditLpSep = (row: any) => {
    const temp: any[] = production.map((current) => {
      if (current.lpSeparatorId !== row.lpSeparatorId) return current;
      return {
        lpSeparatorId: row.lpSeparatorId,
        lpSeparatorName: row.lpSeparatorName,
        lpInletPress: row.lpInletPress,
        lpInletPressPrev: row.lpInletPressPrev,
        lpInletTemp: row.lpInletTemp,
        lpInletTempPrev: row.lpInletTempPrev,
        lpSteamRate: row.lpSteamRate,
        lpSteamRatePrev: row.lpSteamRatePrev,
        lpBrineRate: row.lpBrineRate,
        lpBrineRatePrev: row.lpBrineRatePrev,
      };
    });
    setProduction(temp);
    return row;
  };

  const brinePumpColumns: GridColDef<GridValidRowModel>[] = [
    { field: "brinePumpName", headerName: "BP. Name", width: 110, headerAlign: "center" },
    {
      field: "bpBrineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "bpBrineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
  ];

  const wellColumns: GridColDef<GridValidRowModel>[] = [
    { field: "wellName", headerName: "Well Name", width: 110, headerAlign: "center" },
    {
      field: "fcvPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "fcv",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "whpPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "whp",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "whtPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "wht",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
  ];

  const lpSepColumns: GridColDef<GridValidRowModel>[] = [
    {
      field: "lpInletPressPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "lpInletPress",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "lpInletTempPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "lpInletTemp",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "lpSteamRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "lpSteamRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "lpBrineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "lpBrineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
  ];

  const hpSepColumns: GridColDef[] = [
    { field: "hpSeparatorName", headerName: "HP Sep. Name", width: 110, headerAlign: "center" },
    {
      field: "hpInletPressPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "hpInletPress",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "hpInletTempPrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "hpInletTemp",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "hpSteamRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "hpSteamRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
    {
      field: "hpBrineRatePrev",
      headerName: "Prev.",
      width: 60,
      headerAlign: "center",
      cellClassName: "disabled-cell",
      flex: 1,
    },
    {
      field: "hpBrineRate",
      headerName: "Curr.",
      width: 60,
      headerAlign: "center",
      editable: true,
      flex: 1,
    },
  ];

  const hpSepColumnGroup: GridColumnGroup[] = [
    {
      groupId: "HP Separator",
      headerAlign: "center",
      children: [
        { field: "hpSeparatorName" },
        {
          groupId: "Inlet Press. (Bar)",
          headerAlign: "center",
          children: [{ field: "hpInletPressPrev" }, { field: "hpInletPress" }],
        },
        {
          groupId: "Inlet Temp. (Deg C)",
          headerAlign: "center",
          children: [{ field: "hpInletTempPrev" }, { field: "hpInletTemp" }],
        },
        {
          groupId: "Steam Rate (T/hr)",
          headerAlign: "center",
          children: [{ field: "hpSteamRatePrev" }, { field: "hpSteamRate" }],
        },
        {
          groupId: "Brine Rate (M3/hr)",
          headerAlign: "center",
          children: [{ field: "hpBrineRatePrev" }, { field: "hpBrineRate" }],
        },
      ],
    },
  ];

  const lpSepColumnGroup: GridColumnGroup[] = [
    {
      groupId: "LP Separator",
      headerAlign: "center",
      children: [
        {
          groupId: "Inlet Press. (Bar)",
          headerAlign: "center",
          children: [{ field: "lpInletPressPrev" }, { field: "lpInletPress" }],
        },
        {
          groupId: "Inlet Temp. (Deg C)",
          headerAlign: "center",
          children: [{ field: "lpInletTempPrev" }, { field: "lpInletTemp" }],
        },
        {
          groupId: "Steam Rate (T/hr)",
          headerAlign: "center",
          children: [{ field: "lpSteamRatePrev" }, { field: "lpSteamRate" }],
        },
        {
          groupId: "Brine Rate (M3/hr)",
          headerAlign: "center",
          children: [{ field: "lpBrineRatePrev" }, { field: "lpBrineRate" }],
        },
      ],
    },
  ];

  const wellColumnGroup: GridColumnGroup[] = [
    {
      groupId: "Wells Parameter",
      headerAlign: "center",
      children: [
        { field: "wellName" },
        {
          groupId: "FCV (%)",
          headerAlign: "center",
          children: [{ field: "fcvPrev" }, { field: "fcv" }],
        },
        {
          groupId: "WHP (Bar)",
          headerAlign: "center",
          children: [{ field: "whpPrev" }, { field: "whp" }],
        },
        {
          groupId: "WHT (Deg C)",
          headerAlign: "center",
          children: [{ field: "whtPrev" }, { field: "wht" }],
        },
      ],
    },
  ];

  const BrinePumpColumnGroup: GridColumnGroup[] = [
    {
      groupId: "BrinePump",
      headerAlign: "center",
      children: [
        { field: "brinePumpName" },
        {
          groupId: "Brine Rate (M3/hr)",
          headerAlign: "center",
          children: [{ field: "bpBrineRatePrev" }, { field: "bpBrineRate" }],
        },
      ],
    },
  ];

  useEffect(() => {
    dailysheetStore.setProductions(wells, production, hpSeparators);
  }, [dailysheetStore, wells, production, hpSeparators]);

  useEffect(() => {
    const dataLp = [
      {
        lpSeparatorId: data.lpSeparatorId,
        lpSeparatorName: data.lpSeparatorName,
        lpInletPress: data.lpInletPress,
        lpInletPressPrev: data.lpInletPressPrev,
        lpInletTemp: data.lpInletTemp,
        lpInletTempPrev: data.lpInletTempPrev,
        lpSteamRate: data.lpSteamRate,
        lpSteamRatePrev: data.lpSteamRatePrev,
        lpBrineRate: data.lpBrineRate,
        lpBrineRatePrev: data.lpBrineRatePrev,
      },
    ];
    setProduction(dataLp);
    setWells(data.wells);
    setHpSeparators(data.hpSeparators);
  }, [data]);

  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
      <StyledDataGridPremium
        hideFooter
        rows={wells ?? []}
        getRowId={(row) => row.wellId}
        rowHeight={40}
        columns={wellColumns}
        columnGroupingModel={wellColumnGroup}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        processRowUpdate={handleRowEditWell}
      />
      <StyledDataGridPremium
        rows={hpSeparators ?? []}
        getRowId={(row) => row.hpSeparatorId}
        columns={hpSepColumns}
        getRowHeight={(data) => {
          let temp = data.model.hpSeparatorName.split("/").length;

          return temp * 40;
        }}
        hideFooter
        columnGroupingModel={hpSepColumnGroup}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        rowHeight={(40 * wells.length) / hpSeparators.length}
        onProcessRowUpdateError={(e) => console.error(e)}
        processRowUpdate={handleRowEditHpSep}
      />
      <StyledDataGridPremium
        rows={production ?? []}
        getRowId={(row) => row.lpSeparatorId}
        columns={lpSepColumns}
        hideFooter
        columnGroupingModel={lpSepColumnGroup}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        rowHeight={40 * wells.length}
        processRowUpdate={handleRowEditLpSep}
      />
      <Box sx={{ width: 250 }}>
        <StyledDataGridPremium
          rows={hpSeparators ?? []}
          hideFooter
          getRowId={(row) => row.hpSeparatorId}
          columns={brinePumpColumns}
          columnGroupingModel={BrinePumpColumnGroup}
          showCellVerticalBorder
          showColumnVerticalBorder
          rowHeight={(40 * wells.length) / hpSeparators.length}
          onProcessRowUpdateError={(e) => console.error(e)}
          processRowUpdate={handleRowEditHpSep}
        />
      </Box>
    </Box>
  );
}

export default observer(DailysheetWellsParameterTable);
