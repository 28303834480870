import { Checkbox, FormControl, FormControlLabel, FormGroup, Button, LinearProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { enumMap, Roles } from "../../app/config/enum";
import { useStore } from "../../app/stores/store";

interface Props {
    username?: string
}

function UserFormRoles({ username }: Props) {
    const { userStore, modalStore } = useStore();

    const [curRoles, setCurRoles] = useState<Roles[]>([]);

    useEffect(() => {
        if (username) userStore.getDetail(username).then(userDetail => {
            setCurRoles(userDetail.roles);
            modalStore.setLoading(false);
        });
    }, [username, userStore, modalStore]);

    async function handleOnChange(checked: boolean, role: Roles) {
        const prevRoles = curRoles;
        try {
            if (checked) {
                setCurRoles([...curRoles, role]);
                await userStore.addRole(username!, role);
            } else {
                const removedRole = curRoles.filter(r => r !== role);
                setCurRoles(removedRole);
                await userStore.removeRole(username!, role);
            }
        } catch (error) {
            setCurRoles(prevRoles);
            console.log(error);
        }
    }

    if (userStore.loadingForm) return null;

    return (
        <>
            {userStore.loadingRole && <LinearProgress />}
            <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                    {enumMap(Roles, (key, value, idx) => (
                        <FormControlLabel
                            key={key}
                            value={key}
                            disabled={userStore.loadingRole}
                            control={<Checkbox checked={curRoles.includes(Roles[key as keyof typeof Roles])} />}
                            onChange={(e, checked) => handleOnChange(checked, Roles[key as keyof typeof Roles])}
                            label={value}
                            labelPlacement="end"
                        />
                    ))}
                </FormGroup>
                <FormGroup aria-label="position" row>
                    <Button variant="contained" color="primary" onClick={() => modalStore.close()} >Close</Button>
                </FormGroup>
            </FormControl>
        </>
    );
}

export default observer(UserFormRoles);