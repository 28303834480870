import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { formatDateToMMDDYYYY } from "../config/enum";
import {
  IDailyInjections,
  IDailyInjectionTable,
  IDailyInjRequest,
  IDailyInjRequestTable,
  IDailyMonitoringTable,
  IDailyMonRequest,
  IDailyProdHpSeparator,
  IDailyProdHpSepReq,
  IDailyProdRequest,
  IDailyProductions,
  IDailyProdWells,
  IDailySheet,
  IDailySheetRequest,
} from "../models/dailysheet";

export default class DailysheetStore {
  dailysheet: IDailySheet = {
    dailyLogId: "",
    dailyDate: new Date(),
    createdById: "",
    modifiedById: "",
    status: "",
    remarks: "",
    productions: [],
    injections: [],
    monitorings: [],
  };

  dailysheetReq: IDailySheetRequest = {
    dailyDate: new Date(),
    createdById: "",
    modifiedById: "",
    remarks: "",
    productions: [],
    injections: [],
    monitorings: [],
  };

  loadingGetData = false;
  loadingForm = false;

  constructor() {
    makeAutoObservable(this);
  }

  draftDailySheet = async () => {
    this.loadingForm = true;
    try {
      await agent.Dailysheet.draft(this.dailysheetReq);
    } catch (error) {
      throw error;
    } finally {
      this.loadingForm = false;
    }
  };

  createDailySheet = async () => {
    this.loadingForm = true;
    try {
      await agent.Dailysheet.create(this.dailysheetReq);
    } catch (error) {
      throw error;
    } finally {
      this.loadingForm = false;
    }
  };

  getDailyByDate = async (date: Date) => {
    this.loadingGetData = true;

    try {
      const params = new URLSearchParams();
      if (date) params.append("date", formatDateToMMDDYYYY(date));
      const data = await agent.Dailysheet.getByDate(params);
      this.setDailySheet(data);
      this.setDailyDate(date);
      this.initProdRequest(data.productions);
      this.initInjRequest(data.injections);
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => (this.loadingGetData = false));
    }
  };

  initProdRequest = (data: IDailyProductions[]) => {
    let temp: IDailyProdRequest[] = [];
    data.forEach((item) => {
      let rows: IDailyProdHpSepReq[] = [];
      item.hpSeparators.forEach((row) => {
        const tabData = {
          hpSeparatorId: row.hpSeparatorId,
          hpSeparatorName: row.hpSeparatorName,
          hpInletPress: row.hpInletPress,
          hpInletTemp: row.hpInletTemp,
          hpSteamRate: row.hpSteamRate,
          hpBrineRate: row.hpBrineRate,
          brinePumpId: row.brinePumpId,
          brinePumpName: row.brinePumpName,
          bpBrineRate: row.bpBrineRate,
          wells: row.wells,
        };
        rows.push(tabData);
      });
      temp.push({
        lpSeparatorId: item.lpSeparatorId,
        lpSeparatorName: item.lpSeparatorName,
        lpInletPress: item.lpInletPress,
        lpInletTemp: item.lpInletTemp,
        lpSteamRate: item.lpSteamRate,
        lpBrineRate: item.lpBrineRate,
        hpSeparators: rows,
      });
    });
    this.dailysheetReq.productions = temp;
  };

  initInjRequest = (data: IDailyInjections[]) => {
    let temp: IDailyInjRequest[] = [];
    data.forEach((item) => {
      let rows: IDailyInjRequestTable[] = [];
      item.table.forEach((row) => {
        const tabData = {
          wellId: row.wellId,
          wellName: row.wellName,
          fcv: row.fcv,
          whp: row.whp,
          wht: row.wht,
          hourDuration: row.hourDuration,
          brineRate: row.brineRate,
          avgBrineRate: row.avgBrineRate,
          totalBrineRate: row.totalBrineRate,
        };
        rows.push(tabData);
      });
      temp.push({
        locationId: item.locationId,
        locationName: item.locationName,
        table: rows,
      });
    });
    this.dailysheetReq.injections = temp;
  };

  initMonitoringRequest = (data: IDailyMonitoringTable[]) => {
    let temp: IDailyMonRequest[] = [];
    data.forEach((item) => {
      temp.push({
        wellId: item.wellId,
        wellName: item.wellName,
        whp: item.whp,
        wht: item.wht,
      });
    });
    this.dailysheetReq.monitorings = temp;
  };

  setProductions = (wells: IDailyProdWells[], data: any, hpSep: IDailyProdHpSeparator[]) => {
    this.dailysheetReq.productions.forEach((item) => {
      if (item.lpSeparatorId === data[0].lpSeparatorId) {
        item.lpSeparatorId = data[0].lpSeparatorId;
        item.lpSeparatorName = data[0].lpSeparatorName;
        item.lpInletPress = data[0].lpInletPress;
        item.lpInletTemp = data[0].lpInletTemp;
        item.lpSteamRate = data[0].lpSteamRate;
        item.lpBrineRate = data[0].lpBrineRate;
        item.hpSeparators = hpSep;
        let tempHpSep = item.hpSeparators;
        wells.forEach((row) => {
          tempHpSep.forEach((item) => {
            item.wells.forEach((item2) => {
              if (item2.wellId === row.wellId) {
                item2.wellId = row.wellId;
                item2.wellName = row.wellName;
                item2.fcv = row.fcv;
                item2.whp = row.whp;
                item2.wht = row.wht;
              }
            });
          });
        });
      }
    });
  };

  setInjections = (locId: string, data: IDailyInjectionTable[]) => {
    this.dailysheetReq.injections.forEach((item) => {
      if (item.locationId === locId) {
        let rows: IDailyInjRequestTable[] = [];
        data.forEach((row) => {
          const tabData = {
            wellId: row.wellId,
            wellName: row.wellName,
            fcv: row.fcv,
            whp: row.whp,
            wht: row.wht,
            hourDuration: row.hourDuration,
            brineRate: row.brineRate,
            avgBrineRate: row.avgBrineRate,
            totalBrineRate: row.totalBrineRate,
          };
          rows.push(tabData);
        });
        item.table = rows;
      }
    });
  };

  setMonitorings = (data: IDailyMonitoringTable[]) => {
    let temp: IDailyMonRequest[] = [];
    data.forEach((item) => {
      temp.push({
        wellId: item.wellId,
        wellName: item.wellName,
        whp: item.whp,
        wht: item.wht,
      });
    });
    this.dailysheetReq.monitorings = temp;
  };

  setDailySheet = (data: IDailySheet) => {
    data.productions.forEach((item) => {
      let wellsRow: IDailyProdWells[] = [];
      item.hpSeparators.forEach((row) => {
        wellsRow = [...wellsRow, ...row.wells];
      });
      item.wells = wellsRow;
    });
    this.dailysheet = data;
  };

  setDailyDate = (date: Date) => {
    this.dailysheetReq.dailyDate = date;
  };

  setCreatedBy = (modifiedById: string, createdById: string) => {
    this.dailysheetReq.createdById = createdById;
    this.dailysheetReq.modifiedById = modifiedById;
  };

  setRemarks = (remarks: string) => {
    this.dailysheetReq.remarks = remarks;
  };
}
