import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import RkdForm from "../../app/component/form/RkdForm";
import RkdFormField from "../../app/component/form/RkdFormField";
import RkdTextInput from "../../app/component/form/RkdTextInput";
import { UserFormValues } from "../../app/models/user";
import { useStore } from "../../app/stores/store";

interface Props {
    username?: string
}

function UserForm({username}: Props) {
    const { userStore, modalStore } = useStore();
    const [curUser, setCurUser] = useState(new UserFormValues());

    useEffect(() => {
        if (username) userStore.getDetail(username).then(userDetail => {
            setCurUser(new UserFormValues(userDetail));
            modalStore.setLoading(false);
        });
    }, [username, userStore, modalStore]);

    if (userStore.loadingForm) return null;

    return (
        <RkdForm
            initialValues={curUser}
            submitAction={username ? userStore.editUser : userStore.createUser}
            actionAfterSubmit={() => {
                userStore.getUserList();
                modalStore.close();
            }}
            formTitle={`${username ? 'Edit' : 'Create New'} User`}
            buttonSubmitText="Submit"
            successMessage={`Success ${username ? "editing" : "creating"} User`}
            globalErrorMessage={`Error ${username ? "editing" : "creating"} User`}
        >
            <RkdFormField><RkdTextInput name="username" disabled={!!username} label="Username" /></RkdFormField>
            <RkdFormField><RkdTextInput name="displayName" label="Display Name" /></RkdFormField>
            <RkdFormField><RkdTextInput name="email" label="Email" /></RkdFormField>            
        </RkdForm>
    );
}

export default observer(UserForm);