import MuiList from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import DropdownMenu from './DropdownMenu';
import { menu, Menu } from '../../config/menu';

import theme from '../../config/theme';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../stores/store';

const List = styled(MuiList)({
  '&& .MuiTypography-root': {
    color: theme.palette.info.main,
  },
  '&& .MuiListItemIcon-root ': {
    color: theme.palette.info.main,
  },
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: theme.palette.primary.light,
    '&, & .MuiListItemIcon-root,  .MuiTypography-root ': {
      color: theme.palette.primary.main,
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.info.light,
  },
});

const MenuNav = () => {
  const location = useLocation();
  const { accountStore } = useStore();
  const isSelected = (route: string) => {
    const parentPath = location.pathname.split('/');
    const routePath = route.split('/');
    return parentPath[1] === routePath[1];
  };

  return (
    <List sx={{ width: '100%' }} aria-labelledby="nested-list-subheader">
      {menu.map((item: Menu, idx) => {
        if (item.roles === undefined || (item.roles && accountStore.isInRole(item.roles))) {
          if (item.submenu) { // berarti menu parent
            return (
              <DropdownMenu
                key={idx}
                menu={item.title}
                submenu={item.submenu}
                icon={item.icon}
              />
            );
          } else if (item.route) {
            return (
              <ListItemButton
                key={idx}
                component={Link}
                to={item.route!}
                selected={isSelected(item.route)}
                sx={{
                  borderRadius: 1,
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItemButton>
            )
          }
        }
        return false;
      })}
    </List>
  );
};

export default MenuNav;
