import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import UserList from './UserList';

function UserPage() {
  const { commonStore } = useStore();
  useEffect(() => {
    commonStore.setTitlePage('User Mangement');
  });
  return (
    <>      
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <UserList />
        </Grid>
      </Grid>
    </>
  );
}

export default observer(UserPage);
