import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import Dialog from '@mui/material/Dialog';
import { Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

function RkdDialog() {
    const { dialogStore: { isOpen, close, executeActions, dialog, isLoading } } = useStore();

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {dialog.title &&
                <DialogTitle id="alert-dialog-title">
                    {dialog.title}
                </DialogTitle>
            }

            {dialog.description &&
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialog.description}
                    </DialogContentText>
                </DialogContent>
            }

            <DialogActions>
                <Button onClick={close}>{dialog.closeText ?? (dialog.action ? "Cancel" : "OK")}</Button>

                {dialog.action &&
                    <Button autoFocus onClick={() => executeActions()} disabled={isLoading}>
                        {isLoading ? <CircularProgress /> : (dialog.actionText ?? "OK")}
                    </Button>
                }

            </DialogActions>
        </Dialog>
    );
}

export default observer(RkdDialog);