import { Roles } from "../config/enum";

export interface IUser {
    id?: string;
    username: string;
    displayName: string;
    photo: string;    
    email: string;
}

export interface IUserPhoto {    
    username: string;   
    file?: FormData;
    photo: string;
}

export interface IUserDetail {
    id?: string;
    username: string;
    displayName: string;
    photo: string;
    email: string;
    roles: Roles[];
}

export class UserFormValues implements IUser {
    username = "";
    displayName = "";
    photo = "";
    email = "";

    constructor(user?: IUser) {
        if (user) {
            this.username = user.username;
            this.displayName = user.displayName;
            this.photo = user.photo ?? "";
            this.email = user.email;
        }
    }
}