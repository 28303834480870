import React from 'react';
import { useState } from 'react';
import { useStore } from '../../stores/store';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';

const AvatarAppBar = () => {  
  const { accountStore } = useStore();  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuLogout = () => {
    setAnchorEl(null);
    accountStore.logout();
  }
  const popUpMenu = (
    <Menu
      id='menuAvatar'
      anchorEl={anchorEl}      
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        'aria-labelledby': 'buttonAvatar',
      }}
    >
      { accountStore.isLoggedIn ?
        <MenuItem onClick={handleMenuLogout}>
          Logout
        </MenuItem>
      :         
        <MenuItem component={Link} to="/login" onClick={handleMenuClose}>
          Login
        </MenuItem>
      }            
    </Menu>
  );

  return (
    <>
      <IconButton
        id='buttonAvatar'
        aria-controls={isMenuOpen ? 'menuAvatar' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        onClick={handleProfileMenuOpen}        
      >
        <Avatar alt={accountStore.account?.username} src={accountStore.account?.photo} />
      </IconButton>
      {popUpMenu}
    </>
  );
};

export default AvatarAppBar;
