import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { history } from '../..';
import { IExample } from '../models/example';
import { IAccountInfo, IAccountLoginValues, IAccountRegisterValues } from '../models/account';
import { store } from '../stores/store';
import { IUser, IUserDetail, IUserPhoto } from '../models/user';
import { DataGridResult } from '../models/materialUI/dataGrid';
import { Roles } from '../config/enum';
import { IDailySheet, IDailySheetRequest } from '../models/dailysheet';
import { IQueryChartResponse, IQueryGetSelector, IQueryRequest } from '../models/query';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}
const urlBase = process.env.REACT_APP_API_URL;
const urlSmgp = process.env.REACT_APP_SMGP;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(async response => {
    if (process.env.NODE_ENV === 'development') await sleep(1500);
    response = readResponseHeader(response);
    return response;
}, (error: AxiosError<any>) => {
    const { data, status, config } = error.response!;
    console.log(error.response!);

    switch (status) {
        case 400:
            if (typeof data === 'string') {
                store.snackbarStore.show('error', data);
            }
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(' '+data.errors[key]);
                    }
                }
                // throw modalStateErrors.flat();
                store.snackbarStore.show('error', modalStateErrors.join());
            }
            break;
        case 401:
            store.snackbarStore.show('error', 'Error 401 (Unauthorized)');
            break;
        case 403:
            store.snackbarStore.show('error', 'Error 403 (Forbidden)');
            break;
        case 404:
            history.push('/not-found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            history.push('/server-error');
            break;
    }
    return Promise.reject(error);
});

const readResponseHeader = (response: AxiosResponse): AxiosResponse => {
    if (response.headers["rowcount"]) { // data grid result
        response.data = new DataGridResult(response.data, parseInt(response.headers["rowcount"]));
        return response as AxiosResponse<DataGridResult<any>>;
    }
    return response;
}

const handleBlobResponse = (response: AxiosResponse<Blob>) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    const filename = response.headers["content-disposition"].split('"')[1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T>(url: string, body?: {}) => axios.get<T>(`${urlBase}${url}`, body).then(responseBody),
    getFile: (url: string, body?: AxiosRequestConfig<any>) => axios.get<Blob>(`${urlBase}${url}`, {...body, responseType: 'blob'}).then(response => handleBlobResponse(response)),
    post: <T>(url: string, body?: {}) => axios.post<T>(`${urlBase}${url}`, body).then(responseBody),
    put: <T>(url: string, body?: {}) => axios.put<T>(`${urlBase}${url}`, body).then(responseBody),
    del: <T>(url: string, body?: {}) => axios.delete<T>(`${urlBase}${url}`, {data: body}).then(responseBody),
    postFile: <T>(url: string, body?: {}) => axios.post<T>(`${urlBase}${url}`, body, { headers: {"Content-Type": "multipart/form-data"} }).then(responseBody),
}

const requests2 = {
    get: <T>(url: string, body?: {}) => axios.get<T>(`${urlSmgp}${url}` , body).then(responseBody),
    getFile: (url: string, body?: AxiosRequestConfig<any>) => axios.get<Blob>(`${urlSmgp}${url}` , {...body, responseType: 'blob'}).then(response => handleBlobResponse(response)),
    post: <T>(url: string, body?: {}) => axios.post<T>(`${urlSmgp}${url}` , body).then(responseBody),
    put: <T>(url: string, body?: {}) => axios.put<T>(`${urlSmgp}${url}` , body).then(responseBody),
    del: <T>(url: string, body?: {}) => axios.delete<T>(`${urlSmgp}${url}` , {data: body}).then(responseBody),
    postFile: <T>(url: string, body?: {}) => axios.post<T>(`${urlSmgp}${url}` , body, { headers: {"Content-Type": "multipart/form-data"} }).then(responseBody),
}

const Account = {
    current: () => requests2.get<IAccountInfo>('/account'),
    login: (user: IAccountLoginValues) => requests2.post<IAccountInfo>('/account/login', user),
    register: (user: IAccountRegisterValues) => requests2.post<IAccountInfo>('/account/register', user)
}

const User = {
    list: (params: URLSearchParams) => requests.get<DataGridResult<IUser[]>>('/user', { params }),
    detail: (username: string) => requests.get<IUserDetail>(`/user/${username}`),
    create: (user: IUser) => requests.post<void>('/user', user),
    edit: (user: IUser) => requests.put<void>(`/user/${user.username}`, user),
    delete: (username: string) => requests.del<void>(`/user/${username}`),
    upload: (data: IUserPhoto) => requests.postFile<void>(`/user/${data.username}/photo`, data.file),
    addRole: (username: string, role: Roles) => requests.post<void>(`/user/${username}/role`, {name: role}),
    removeRole: (username: string, role: Roles) => requests.del<void>(`/user/${username}/role`, {name: role}),
    resetPass: (username: string) => requests.put<void>(`/user/${username}/reset`),    
}

const Role = {
    
}

const Example = {
    list: (params: URLSearchParams) => requests.get<IExample[]>('/example', {params}), // name must exactly "params"
    listGrid: (params: URLSearchParams) => requests.get<DataGridResult<IExample[]>>('/example/grid', {params}), // because it's a field called "params"
    detail: (id: string) => requests.get<IExample>(`/example/${id}`),
    create: (example: IExample) => requests.post<void>('/example', example),
    edit: (example: IExample) => requests.put<void>(`/example/${example.id}`, example),
    delete: (id: string) => requests.del<void>(`/example/${id}`),
}

const Dailysheet = {
    getByDate: (params: URLSearchParams) => requests.get<IDailySheet>(`/DailyLog/date/`, {params}),
    create: (data: IDailySheetRequest) => requests.post<void>('/DailyLog', data),
    draft: (data: IDailySheetRequest) => requests.post<void>('/DailyLog/draft', data)
}

const Query = {
    list: () => requests.get<IQueryGetSelector>(`/DailyLog/chart/selectors`),
    load: (data: IQueryRequest) => requests.post<IQueryChartResponse>(`/DailyLog/chart/query`, data)
}

const agent = {
    Account,
    User,
    Role,
    Example,
    Dailysheet,
    Query
}

export default agent;