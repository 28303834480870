import ApexCharts from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import React, { useEffect } from 'react'
import { IQueryChart } from '../../../app/models/query';

interface IProps {
  series: IQueryChart[]
}

const initCharts: IQueryChart[] = [{
  name: '',
  data: [
    {
      x: '',
      y: null
    }
  ]
}]


function QueryCharts(props: IProps) {

    const [ series, setSeries ] = React.useState(initCharts);

    useEffect(() => {
      if (props.series) {
        setSeries(props.series)
      }
    },[props.series]);

    const options: ApexCharts.ApexOptions  = {
        xaxis: {
          type: 'numeric',
          tickAmount: 8,
        }
        // yaxis: {
        //   title: {
        //     text: y_label.toLocaleUpperCase()
        //   },
        //   max:  Math.max.apply(Math, data.map((item) => item[y_label])),
        // },
        // title: {
        //   align: 'left',
        //   text: 'Well',
        // },
    };
  return (
    <>
    <ReactApexChart options={options}  series={series} />
    </>
  )
}

export default QueryCharts