import { LoadingButton } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStore } from '../../stores/store';

type types = 'warning' | 'danger' | 'success'
interface Props {
  type: types;
  id: string;
  text: string;
  submitAction?: () => Promise<any>,
  afterSubmit?: () => any
}

function ConfirmationPopUp(props: Props) {
  const { type, text, id, submitAction, afterSubmit } = props;
  const { modalStore } = useStore();
  const [loading, setLoading] = useState(false);
  const handleOnclick = async () => {
    if (submitAction) {
      setLoading(true);
      try {
        await submitAction();
        setLoading(false);
        modalStore.close();
        if (afterSubmit) afterSubmit();
      } catch (error) {
        throw error;
      }
    }
  }
  return (
    <Box>
      <Typography variant='h2'>Confirm the action</Typography>
      <Typography variant='body1' sx={{ my: '18px' }}>{text} {id}?</Typography>
      <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'space-between', width: '150px' }}>
        <Button variant='contained' color='info' disabled={loading} onClick={() => modalStore.close()}>Cancel</Button>
        {type === 'warning' && <LoadingButton variant='contained' color='warning' loading={loading} onClick={() => handleOnclick()}>Yes</LoadingButton>}
        {type === 'danger' && <LoadingButton variant='contained' color='error' loading={loading} onClick={() => handleOnclick()}>Yes</LoadingButton>}
        {type === 'success' && <LoadingButton variant='contained' color='success' loading={loading} onClick={() => handleOnclick()}>Yes</LoadingButton>}
      </Box>
    </Box>
  );
}

export default observer(ConfirmationPopUp);