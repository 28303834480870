import { Box, Card, CardContent, CardHeader, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
  title?: string;
  subtitle?: string;
  children?: JSX.Element | JSX.Element[];
}

const RkdCard = (props: Props) => {
  const { children, title, subtitle } = props;
  const child = React.Children.toArray(children);
  return (
    <Card sx={{ boxShadow: 2, mb: 3, backgroundColor: "#FEFEFF" }}>
      {title && (
        <CardHeader
          title={title}
          subheader={subtitle}
          titleTypographyProps={{ variant: "h2", color: "white" }}
          subheaderTypographyProps={{ variant: "subtitle1", color: "white" }}
          sx={{ bgcolor: "#43c55f" }}
        />
      )}

      <Divider />
      <CardContent>
        <Box sx={{ height: "fit-content", display: "flex", flexDirection: "column" }}>
          {child.length >= 1
            ? child.map((item, idx) => (
                <Box key={idx} sx={{ my: 1 }}>
                  {item}
                </Box>
              ))
            : ""}
        </Box>
      </CardContent>
    </Card>
  );
};

export default observer(RkdCard);
