import { Box, Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../../app/config/theme';
import RkdForm from '../../app/component/form/RkdForm';
import RkdTextInput from '../../app/component/form/RkdTextInput';
import { IAccountLoginValues } from '../../app/models/account';
import { useStore } from '../../app/stores/store';
import RkdCheckbox from '../../app/component/form/RkdCheckbox';


const initialValuesLogin: IAccountLoginValues = {
  email: '',
  password: '',
  rememberMe: false,
}

const validationSchema = Yup.object({
  email: Yup.string().email(),
  password: Yup.string().required(),
});


const LoginPage = () => {  
  const { accountStore } = useStore();
  return (
    <>
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Rekadia | Login</title>
      </Helmet>
      <CssBaseline />
      <Grid container sx={{ height: '100vh' }}>        
        <Grid item xs={5}>
          <Box sx={{ height:'100%', display: 'flex', alignItems: 'center'  }}>
            <Box sx={{ m:'auto', width: '50%', display: 'flex', flexDirection: 'column', justifyContent:'center', }}>
              <img src='/assets/images/logo-rekadia.png' alt='Logo Rekadia' style={{ width: '50%', objectFit:'cover', margin:'auto' }} />
              <Typography variant='h2' sx={{ mt: 5, mb: 2, textAlign: 'center' }}>Login</Typography>          
              <RkdForm initialValues={initialValuesLogin} validationSchema={validationSchema} buttonSubmitText="Submit"
                  submitAction={accountStore.login}
              >
                    <RkdTextInput name="email" type="text" label="Email" />
                    <RkdTextInput name="password" type="password" label="Password" />
                    <RkdCheckbox name='rememberMe' label='Remember Me?' />
              </RkdForm>
            </Box>
          </Box>
        </Grid>   
        <Grid item xs={7}>
          <Box sx={{ height:'100%', }}>
            <img src='/assets/images/bg-login.jpg' alt='Hero' style={{ width:'100%', height: '100%', objectFit:'cover' }} />
          </Box>
        </Grid>
      </Grid>      
    </ThemeProvider>
    </>
  );
};

export default LoginPage;