import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import DialogStore from "./helper/dialogStore";
import ExampleStore from "./exampleStore";
import ModalStore from "./helper/modalStore";
import SnackbarStore from "./helper/snackbarStore";
import UserStore from "./userStore";
import DailysheetStore from "./dailysheetStore";
import QueryStore from "./queryStore";

interface Store {
    exampleStore: ExampleStore;
    commonStore: CommonStore;
    accountStore: AccountStore;
    userStore: UserStore;
    snackbarStore: SnackbarStore;
    modalStore: ModalStore;
    dialogStore: DialogStore;
    dailysheetStore: DailysheetStore;
    queryStore: QueryStore;
}

export const store: Store = {
    exampleStore: new ExampleStore(),
    commonStore: new CommonStore(),
    accountStore: new AccountStore(),
    userStore: new UserStore(),
    snackbarStore: new SnackbarStore(),
    modalStore: new ModalStore(),
    dialogStore: new DialogStore(),
    dailysheetStore: new DailysheetStore(),
    queryStore: new QueryStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}